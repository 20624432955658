<script setup>
import {computed, onMounted, watch} from "vue";
import {useStore} from "vuex";

const store = useStore();
const enableAccessibly = computed(() => store.state.User.providerConfiguration?.provider?.enableAccessibly);
let accessibility;
const checkEnableAccessibly = () => {

  if (!enableAccessibly.value) {
    accessibility.classList.add('hidden');
  } else {
    accessibility.classList.remove('hidden');
  }
}
watch(() => enableAccessibly.value, () => {
  checkEnableAccessibly()
})
const checkIfAccessiblyMounted = () => {
  accessibility = document.querySelector('#accessiblyAppWidgetButton')
  if (!accessibility) {
    window.setTimeout(checkIfAccessiblyMounted,500);
    return

  } else {
  checkEnableAccessibly()
  }
}
onMounted(() => {
  checkIfAccessiblyMounted()
})
</script>
<template>
  <router-view/>
</template>

<style>
* {
  scrollbar-width: auto;
  scrollbar-color: var(--color-primary-300) #ffffff;
}

*:hover {
  scrollbar-width: auto;
  scrollbar-color: var(--color-primary-500) #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-primary-300);
  border-radius: 10px;
  border: 3px solid #ffffff;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-primary-500);
  border-radius: 10px;
  border: 3px solid #ffffff;
}

</style>
